import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData, setImgSrc } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { SpinnerCircular, SpinnerRound } from 'spinners-react';

//import PrimScene from "./pmLib/scene";
//import PrimApp from "./pm_index";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


/////////////////////
// Canvas Component
/////////////////////
const Canvas = props => {
  
  const canvasRef = useRef(null)
  
 /*
  const draw = (ctx, scene, frameCount) => {

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.fillStyle = '#ff0000'
    ctx.beginPath()
    ctx.arc(50, 100, 20*Math.sin(frameCount*0.05)**2, 0, 2*Math.PI)
    ctx.fill()

    PrimApp.animateAndRender(scene, ctx, frameCount / 60);
  }
  
  useEffect(() => {
    
    console.log("Hi useEffect.");
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    let frameCount = 0
    let animationFrameId
    
    //Our draw came here
    const render = () => {
      frameCount++
      draw(context, props.scene, frameCount)
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()
    
    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [draw])
  */

  return <canvas ref={canvasRef} {...props}/>
}

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 0px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 1000px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 300px;
  @media (min-width: 767px) {
    width: 900px;
  }
  transition: width 0.5s;
  transition: height 0.5s;

  borderStyle: "solid",
  borderColor: "#ffffff",
  borderWidth: 2,
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);

  border: 2px solid white;
  border-radius: 0%;

  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledImgThumb = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);

  border: 2px solid white;
  border-radius: 0%;

  width: 80px;
  @media (min-width: 900px) {
    width: 100px;
  }
  @media (min-width: 1000px) {
    width: 120px;
  }
  transition: width 0.5s;
`;

export const StyledImgIcon32 = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 2px none white;
  border-radius: 0%;
  width: 32px;
  height: 32px;
`;


export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const mintMsg = "Select quantity and click 'MINT'";
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(mintMsg);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // create a scene
 // console.log("create new scene");
 // const scene = new PrimScene.Scene("MyScene");
 // scene.CreateRandom();
var scene = null;

    

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = gasLimit * mintAmount;
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    //var mintingMsg = `Minting your ${CONFIG.NFT_NAME} NFT`;
    var mintingMsg = `Minting in progress  `;  
    mintingMsg += (mintAmount > 1) ? "(s)" : "";
    setFeedback(mintingMsg);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        //gasLimit: String(totalGasLimit),
        //see: https://web3js.readthedocs.io/en/v1.2.11/web3-eth-contract.html#contract-send
        // gasPrice
        // gas
        //gas: 3000000,
        gas: totalGasLimit,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        if (err.code == '4001')
          setFeedback(mintMsg); 
        else    
          setFeedback("Something might have gone wrong. Please wait to see if the transaction succeeds. If not try again.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Congratulations, check your wallet soon, ${CONFIG.NFT_NAME} is yours!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > CONFIG.MAX_MINT_AMOUNT) {
      newMintAmount = CONFIG.MAX_MINT_AMOUNT;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    setInterval(() => {
      //console.log("Hi Timer");
     // setCurrentImageSrcName("./config/images/pm/80.svg");

      var gridIndex =  Math.floor(Math.random() * 10);      // (0 to 9)
      var imgIndex = Math.floor(Math.random() * 1000) + 1;    // 1 to 1000   
     dispatch(setImgSrc(gridIndex, "./config/images/pm/" + imgIndex + ".svg"));
      
    }, 1000);
  }, []);
   
  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/newLogo.png"} />

        <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", display: "none"}}>
          The Primitive Man project begins now with these Gen1 NFTs.
        </s.TextDescription>
        <s.SpacerSmall />
        <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", }}>
          The launch of Primitive Man begins the journey of 10,000 procedurally-generated unique NFTs.  Evolve and prosper!
        </s.TextDescription>
        <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", }}>
          * No art was harmed in the making of these NFTs.
        </s.TextDescription>
        <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", }}>
          <a href="https://opensea.io/collection/primitivemannft">View collection on Opensea</a>
        </s.TextDescription>

        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 0,
              //border: "4px var(--secondary)",
              borderStyle: "solid",
              borderColor: "#ffffff",
              borderWidth: 2,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextDescription
                style={{
                  textAlign: "center",
                }}
            >
                Each NFT is completely unique, 100% procedurally generated by hand-crafted code. Once all are minted then the blueprint creation code will be shredded and no more can be created.
            </s.TextDescription>
            <s.SpacerLarge />
            <s.TextDescription
                style={{
                  textAlign: "center",
                }}
            >
                Each Primitive Man is animatable and dynamic. The scene data is on IPFS and referenced in the metadata, giving you complete control.
            </s.TextDescription>
            <s.SpacerLarge />
            <s.TextDescription style={{ textAlign: "center", }}>
                As the owner of a Generation 1 Primitive Man you will always get first access to and special deals for all future Primitive Man projects. 
            </s.TextDescription>
          </s.Container>
          <s.SpacerLarge />

          <s.Container
            id="TEMP_MINT_DATE_ANNOUNCEMENT"
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              display: "none",
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 0,
              //border: "4px var(--secondary)",
              borderStyle: "solid",
              borderColor: "#ffffff",
              borderWidth: 2,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextDescription
              style={{
                textAlign: "center",
                fontSize: 48
              }}
            >
              Coming Soon
            </s.TextDescription>
          </s.Container>

          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 0,
              //border: "4px var(--secondary)",
              borderStyle: "solid",
              borderColor: "#ffffff",
              borderWidth: 2,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs {CONFIG.WEI_COST * 10**-18}{" "}
                  {CONFIG.NETWORK.SYMBOL}
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  (+ gas)
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                      <SpinnerCircular size={25} color='var(--secondary)' enabled={claimingNft} />
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>

                      {(claimingNft == false) && 
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>

                      }

                      <SpinnerRound color = 'var(--secondary)' enabled={claimingNft} />          
                    </s.Container>

                  </>
                )}
              </>
            )}
            <s.SpacerMedium />


          
            <s.TextDescription
            style={{
              display: "none",
              textAlign: "center",
              color: "var(--primary-text)",
              fontSize: 12,
            }}
          >
            Please make sure you are connected to {CONFIG.NETWORK.NAME} with the correct address.
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>

          <s.SpacerSmall />
          <s.TextDescription
            style={{
              display: "none",
              textAlign: "center",
              color: "var(--primary-text)",
              fontSize: 12,
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} to maximize the chance of a successful mint. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>


        </s.Container>
        <s.SpacerLarge />
        <s.Container flex={1} fd={"column"} jc={"center"} ai={"center"}>
          <s.Container flex={1} fd={"row"} jc={"center"} ai={"center"}>    
              <StyledImgThumb 
                      alt={"example"} src={data.imgSrc[0]} />  
              <StyledImgThumb 
                         alt={"example"} src={data.imgSrc[1]} />  
                  <StyledImgThumb 
                         alt={"example"} src={data.imgSrc[2]}  />    
            </s.Container>

            <s.Container flex={1} fd={"row"} jc={"center"} ai={"center"}>    
              <StyledImgThumb 
                      alt={"example"} src={data.imgSrc[3]} />  
              <StyledImgThumb 
                         alt={"example"} src={data.imgSrc[4]}  />  
                  <StyledImgThumb 
                         alt={"example"} src={data.imgSrc[5]}  />    
            </s.Container>
            <s.Container flex={1} fd={"row"} jc={"center"} ai={"center"}>    
              <StyledImgThumb 
                      alt={"example"} src={data.imgSrc[6]} />  
              <StyledImgThumb 
                         alt={"example"} src={data.imgSrc[7]} />  
                  <StyledImgThumb 
                         alt={"example"} src={data.imgSrc[8]} />    
            </s.Container>
          </s.Container>

        </ResponsiveWrapper>
        <s.SpacerMedium />

        <s.Container jc={"center"} ai={"center"} style={{ width: "70%", display: 'none'}}>
            <Canvas width={600} height={700} scene={scene}/>
        </s.Container>


        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>

          <s.SpacerLarge />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
              fontSize: 36,
            }}
          >
            FAQ
          </s.TextDescription>

          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--secondary)", }}>
            How many Primitive Man Gen1 NFTs are there?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center",color: "var(--primary-text)", fontSize: 14,}}>
            10k, each randomly created and uploaded to IPFS prior to mint, complete with scene descriptions. 
            100 will be reserved at launch as giveaways to people who helped us on this journey.
          </s.TextDescription> 

          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--secondary)", }}>
            How much do the Gen1 NFTs cost?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center",color: "var(--primary-text)", fontSize: 14,}}>
            10 MATIC
          </s.TextDescription> 

          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--secondary)", }}>
            When will the Gen1 NFTs drop?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center",color: "var(--primary-text)", fontSize: 14,}}>
            Sometime in January, 2022. Unless it happens on Dec. 31, 2021.
          </s.TextDescription> 

          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--secondary)", }}>
            Who are the creators?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center",color: "var(--primary-text)", fontSize: 14,}}>
            We are super-uncool developers who build cool stuff.
          </s.TextDescription> 

          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--secondary)", }}>
            What can I do with my Gen1 NFT?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center",color: "var(--primary-text)", fontSize: 14,}}>
            You have full rights to do whatever you wish with your NFT, commercial or otherwise. You will also receive royalties if your NFT is featured in any future project (game, movie, app).
            Your Gen1 NFT will get you VIP access to all future events, apps, drops, etc.
            Finally, as the owner of a Gen1 NFT, you have our eternal gratitude for making this project happen.
          </s.TextDescription> 

          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--secondary)", }}>
            Have you considered allowing users to “create” their own Primitive Man using the Primitive Man generator?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center",color: "var(--primary-text)", fontSize: 14,}}>
            Yes and we like the idea. Gen1 will release randomly pre-generated, but we will do future drops that gamify the generation with user control.
            If/when the Gen1 mint sells-out we will finish and release our Primitive Pet generator and allow a Gen1 token owner to create and mint their own (for free + gas). 
          </s.TextDescription>
          
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--secondary)", }}>
             Speaking of the Primitive Man Generator, what is it?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center",color: "var(--primary-text)", fontSize: 14,}}>
            It's the tool that generates the Primitive Man NFTs. You can see the prototype generator here:  
            &nbsp;  &nbsp; <a href="https://www.primitiveman.com/prototype_generator/">Prototype Generator</a>
          </s.TextDescription>    

          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--secondary)", }}>
             Is this considered "generative art"?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center",color: "var(--primary-text)", fontSize: 14,}}>
            Yes, in its purest form. Unlike other similar projects, there is no art input, only art output.
          </s.TextDescription>   

          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--secondary)", }}>
             How does this work?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center",color: "var(--primary-text)", fontSize: 14,}}>
            The core algorithm is simple: it assembles a scene description based on coded rules, then the scene is rendered.
            The blueprint code is the result of several months of iteration and experimentation. 
            It is a dense mass of code, data, algorithms and recursion (picture an artist's studio after months of furious creation).
            It gives each Primitive Man a 100% unique look, and without it a Primitive Man cannot be created.
            There are code paths that have rarely or never been exercised, which is going to produce some "Happy Little Accidents" (Thank you Bob Ross). And that's awesome!
            The blueprints will be destroyed once all 10k are minted, and none will ever be created again (like destroying the block or plate after a print run).
          </s.TextDescription>  

          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--secondary)", }}>
             What will you do with the money from the sale of the Gen1 NFTs?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center",color: "var(--primary-text)", fontSize: 14,}}>
             The funds will be used to continue developing Primitive Man. And in everything we do, the Gen1 NFTs will have VINFT ("Very Important NFT") utility.
          </s.TextDescription> 

          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--secondary)", }}>
             What happens if and when you sell all Gen1 NFTs?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center",color: "var(--primary-text)", fontSize: 14,}}>
            Development on roadmap projects will rapidly accelerate.
          </s.TextDescription> 

          <s.SpacerSmall />
          <s.TextDescription style={{textAlign: "center", color: "var(--secondary)",}}>
            What are the future plans/roadmap?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", fontSize: 14, }}>
            We are open to feedback from Gen1 owners, but we have already started tinkering on:      
            </s.TextDescription> 
            <s.SpacerSmall />
            <div>
            <ul style={{ textAlign: "left", color: "var(--primary-text)", fontSize: 12, }}>   
              <li>* Using your NFT as an Augmented Reality mask in video chat/social media apps.</li>  
              <li>* An Augmented Reality gallery (though your NFT will rarely stay in the frame, those pranksters!).</li>
              <li>* A cartoon series improvised and streamed live, where you and your NFT play roles both large and small (participation optional).</li>
              <li>* High-quality Primitive Man T-Shirts</li>
              <li>* Primiverse (metaverse what?)</li>
              <li>* Game-ifying the SmartContract and Blockchain EVM.</li>

            </ul>
            </div>

          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--secondary)",
            }}
          >
            Why isn't my question answered here?
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
              fontSize: 14,
            }}
          >
            Good question! Please post your question to our twitter and we will respond!
          </s.TextDescription>

        </s.Container>

        <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              flex: 0,
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 0,
              //border: "4px var(--secondary)",
              borderStyle: "none",
              borderColor: "#ffffff",
              borderWidth: 0,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <a href="https://twitter.com/primitivemannft">
              <StyledImgIcon32 alt={"twitter"}
                        src={"/config/images/twitter_icon_512.png"}/>
            </a>
          </s.Container>
      

      </s.Container>
    </s.Screen>
  );
}

export default App;
