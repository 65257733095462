const initialState = {
  loading: false,
  totalSupply: 0,
  cost: 0,
  error: false,
  errorMsg: "",

  currentImageSrcName: "./config/images/pm/444.svg",
  imgSrc: ["./config/images/pm/9.svg",
            "./config/images/pm/1.svg",
            "./config/images/pm/2.svg",
            "./config/images/pm/3.svg",
            "./config/images/pm/4.svg",
            "./config/images/pm/5.svg",
            "./config/images/pm/6.svg",
            "./config/images/pm/7.svg",
            "./config/images/pm/8.svg",          
]
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_IMAGE_REQUEST":
      const newImgSrc = state.imgSrc.slice(); //copy the array
      newImgSrc[action.payload.gridIndex] = action.payload.imgSrc;
      return {
        ...state,
        imgSrc: newImgSrc,
      };    
    case "CHECK_DATA_REQUEST":
      console.log("Hi CHECK_DATA_REQUEST");
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
